export class Settings {
  id: number | undefined;
  companyName: string | undefined;
  companyTitle: string | undefined;
  companyContactNo: string | undefined;
  companyMobileNo: string | undefined;
  companyLogoPath: string | undefined;
  companyBannerPath: string | undefined;
  companyQRCodePath: string | undefined;
  startingOrderNo: string | undefined;
  minimumOrderAmount: number | undefined;
  maximumOrderAmount: number | undefined;
  deliveryCharge: number | undefined;
  addedBy: string | undefined;
  addedDate: Date | undefined;
  updatedBy: string | undefined;
  updatedDate: Date | undefined;
}
