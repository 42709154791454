import React, { useState } from "react";
import { Settings } from "../models/Settings.model";

export interface ISettingsContext {
  contextSettings: Settings;
  updateContextSettings: (settings: Settings) => void;
}

export const SettingsContext = React.createContext<ISettingsContext>({
  contextSettings: new Settings(),
  updateContextSettings: (settings: Settings) => console.log(settings),
});

export const SettingsContextProvider = (props: {
  children:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
}) => {
  const [contextSettings, setContextSettings] = useState<Settings>(
    new Settings()
  );

  const updateContextSettings = (settings: Settings) => {
    setContextSettings(settings);
  };

  return (
    <SettingsContext.Provider
      value={{
        contextSettings: contextSettings,
        updateContextSettings: updateContextSettings,
      }}
    >
      {props.children}
    </SettingsContext.Provider>
  );
};
