import { useEffect, useState } from "react";
import axios from "axios";
import { Header } from "../../_components/header";
import { NotificationModal } from "../../_components/modals/notification-modal";
import "./Contact.css";

export const Contact = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [noticePath, setNoticePath] = useState<string>("");

  useEffect(() => {
    setShowModal(true);
    let url = `${process.env.REACT_APP_API_URL}/page-settings/contact`;

    setIsLoading(true);

    try {
      axios.get(url).then((response: any) => {
        if (response.data.length > 0) {
          setNoticePath(response.data[0].NoticeImagePath);
        }
      });
    } catch (error) {
      // Handle Error
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <Header />
      <div className="">
        <div className="smgm-contact-title">
          <h2>Contact Details</h2>
        </div>
        <div className="h-100 d-flex justify-content-center">
          <div className="container smgm-contact-content">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-2 col-3 smgm-contact-label">
                Phone Number:
              </div>
              <div className="col-lg-2 col-9 smgm-contact-value">
                01-4951920
              </div>
            </div>
            <div className="row row-cols-lg-6 d-flex justify-content-center">
              <div className="col-lg-2 col-3 smgm-contact-label">
                Mobile Number:
              </div>
              <div className="col-lg-2 col-9 smgm-contact-value">
                +977 9841862943
              </div>
            </div>
            <div className="row row-cols-lg-6 d-flex justify-content-center">
              <div className="col-lg-2 col-3 smgm-contact-label">
                Whats App:
              </div>
              <div className="col-lg-2 col-9 smgm-contact-value">
                +977 9841862943
              </div>
            </div>
            <div className="row row-cols-lg-6 d-flex justify-content-center">
              <div className="col-lg-2 col-3 smgm-contact-label">Viber:</div>
              <div className="col-lg-2 col-9 smgm-contact-value">
                +977 9841862943
              </div>
            </div>
            <div className="row row-cols-lg-6 d-flex justify-content-center">
              <div className="col-lg-2 col-3 smgm-contact-label">Email:</div>
              <div className="col-lg-2 col-9 smgm-contact-value">
                samyuktamanab.grocery@gmail.com
              </div>
            </div>
            <div className="row row-cols-lg-6 d-flex justify-content-center">
              <div className="col-lg-2 col-3 smgm-contact-label">Website:</div>
              <div className="col-lg-2 col-9 smgm-contact-value">
                <a href="https://grocery.samyuktamanab.com.np">
                  https://grocery.samyuktamanab.com.np
                </a>
              </div>
            </div>
            <div className="row row-cols-lg-6 d-flex justify-content-center">
              <div className="col-lg-2 col-3 smgm-contact-label">Facebook:</div>
              <div className="col-lg-2 col-9 smgm-contact-value">
                {" "}
                <a href="https://facebook.com/grocery.samyuktamanab">
                  https://facebook.com/grocery.samyuktamanab
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal && noticePath && (
        <NotificationModal
          imagePath={process.env.REACT_APP_STATIC_API_URL + "/pages/" + noticePath}
          onClose={handleClose}
        />
      )}
    </>
  );
};
