import AppRouter from "./AppRouter";
import { ProductContextProvider } from "./context/ProductContext";
import { OrderNumberContextProvider } from "./context/OrderNumberContext";
import { SettingsContextProvider } from "./context/SettingsContext";
import { CategoryIdContextProvider } from "./context/CategoryIdContext";
import { ProductNameContextProvider } from "./context/ProductNameContext";
import "./App.css";

function App() {
  return (
    <>
      <div className="App">
        <SettingsContextProvider>
          <ProductContextProvider>
            <OrderNumberContextProvider>
              <CategoryIdContextProvider>
                <ProductNameContextProvider>
                  <AppRouter />
                </ProductNameContextProvider>
              </CategoryIdContextProvider>
            </OrderNumberContextProvider>
          </ProductContextProvider>
        </SettingsContextProvider>
      </div>
    </>
  );
}

export default App;
