import { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import {
  SettingsContext,
  ISettingsContext,
} from "../../context/SettingsContext";
import { IProductContext, ProductContext } from "../../context/ProductContext";
import { UpdateQuantityButton } from "../../_components/buttons/update-quantity-button";
import { OrderModal } from "../modals/order-modal";
import { Spinner } from "../spinner";
import { OrderDetail } from "../../models/OrderDetail.model";
import { OrderItem } from "../../models/OrderItem.model";
import "./ProductCart.css";

export interface IProductCartProps {
  onComplete: (orderNumber: string) => void;
}

export const ProductCart = (props: IProductCartProps) => {
  const history = useHistory();
  const { contextSettings, updateContextSettings } = useContext<
    ISettingsContext
  >(SettingsContext);

  const { contextProducts, updateContextProducts } = useContext<
    IProductContext
  >(ProductContext);

  const [subTotal, setSubTotal] = useState<number>(0.0);
  const [memberId, setMemberId] = useState<string>("");
  const [isMemberIdInvalid, setIsMemberIdInvalid] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [contactNumber, setContactNumber] = useState<number>();
  const [address, setAddress] = useState<string>("");
  const [orderNumberDisplay, setOrderNumberDisplay] = useState<string>("");
  const [enableOrderButton, setEnableOrderButton] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (contextProducts.length === 0) {
      history.push("/");
    } else {
      updateSubTotal();
    }
  }, []);

  useEffect(() => {
    if (
      name &&
      contactNumber &&
      address &&
      subTotal >= (contextSettings.minimumOrderAmount || 0)
    ) {
      setEnableOrderButton(true);
    } else {
      setEnableOrderButton(false);
    }
  }, [name, contactNumber, address, subTotal]);

  const handleChangeQuantityButtonClick = (
    productId: number,
    value: number
  ) => {
    updateProducts(productId, value);
    updateSubTotal();
  };

  const getOrderNumberDisplay = (orderNumber: number): string => {
    const startingOrderNo = contextSettings.startingOrderNo || "00000";
    const orderNumberDisplay = orderNumber
      .toString()
      .padStart(startingOrderNo.length, "0");
    return orderNumberDisplay;
  };

  const handleRemoveClick = (productId: number) => {
    contextProducts.splice(
      contextProducts.findIndex(
        (contextProduct) => contextProduct.id === productId
      ),
      1
    );

    updateSubTotal();
  };

  const handleOrder = () => {
    var regex = /^[mn]\d{4}$/i;
    if (memberId.length > 0 && !regex.test(memberId)) {
      setIsMemberIdInvalid(true);
    } else {
      setIsMemberIdInvalid(false);
      let maxOrderNumber = 0;
      setIsLoading(true);
      try {
        axios
          .get(`${process.env.REACT_APP_API_URL}/orders/max-order-number`)
          .then((response: any) => {
            maxOrderNumber = parseInt(
              (response?.data?.maxOrderNumber || 0).toString()
            );
            maxOrderNumber += 1;
            const maxOrderNumberDisplay = getOrderNumberDisplay(maxOrderNumber);
            const now = new Date().toISOString().slice(0, 19).replace("T", " ");

            const orderItems: OrderItem[] = [];
            contextProducts.forEach((product) => {
              if (product.addedQuantity && product.addedQuantity > 0) {
                const orderItem: OrderItem = {
                  orderNumberDisplay: maxOrderNumberDisplay,
                  itemId: product.itemId,
                  code: product.code,
                  batchNumber: product.batchNumber,
                  subCode: product.subCode,
                  price: product.price,
                  quantity: product.addedQuantity,
                  createdDate: now,
                };

                orderItems.push(orderItem);
              }
            });

            const data: OrderDetail = {
              orderNumber: maxOrderNumber,
              orderNumberDisplay: maxOrderNumberDisplay,
              memberId: memberId,
              name: name,
              contactNumber: contactNumber,
              address: address,
              subTotal: subTotal,
              discount: 0.0,
              tax: 0.0,
              deliveryCharge: contextSettings?.deliveryCharge,
              createdDate: now,
              items: orderItems,
            };

            axios
              .post(`${process.env.REACT_APP_API_URL}/orders`, data)
              .then((response) => {
                setOrderNumberDisplay(data.orderNumberDisplay || "");
                const smsNotification = {
                  AdminContactNumber: contextSettings.companyMobileNo,
                  AdminMessage: `New order # ${data.orderNumberDisplay} by ${name} - ${contactNumber} - ${memberId}`,
                  CustomerContactNumber: contactNumber,
                  CustomerMessage: `Dear ${name}, your order # is ${data.orderNumberDisplay}. Your order placed successfully - Samyukta Manab Online Grocery Store.`,
                };
                // TODO: Control this from the settings.
                sendSMS(smsNotification);
                props.onComplete(data.orderNumberDisplay || "");
              })
              .catch((error) => console.log(error));
          })
          .catch((error) => console.log(error));
      } catch (error) {
        // Handle error
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleBackToShopping = () => {
    history.push("/");
  };

  const updateProducts = (productId: number, value: number) => {
    const contextIndex = contextProducts.findIndex(
      (product) => product.id === productId
    );
    if (contextIndex >= 0) {
      let tempContextProducts = [...contextProducts];
      tempContextProducts[contextIndex].addedQuantity = value;
      updateContextProducts(tempContextProducts);
    }
  };

  const updateSubTotal = () => {
    let subTotal = 0;
    contextProducts.forEach((product) => {
      subTotal += (product.addedQuantity || 0) * (product.price || 0);
    });

    setSubTotal(subTotal);
  };

  const sendSMS = (data: any) => {
    try {
      axios
        .post(`${process.env.REACT_APP_API_URL}/sms`, data)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
    } finally {
    }
  };

  return (
    <>
      {isLoading && <Spinner />}
      <div className="container" style={{ padding: "15px" }}>
        <div className="row">
          <div className="col-lg-9">
            <div className="card border shadow-0">
              <div className="m-3">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <strong>Your shopping cart</strong>
                  </div>
                  <div>
                    {contextProducts.reduce(
                      (a, b) => a + (b["addedQuantity"] || 0),
                      0
                    )}{" "}
                    item/s
                  </div>
                </div>
                <hr />
                {contextProducts.map((product, index) => (
                  <div key={index} className="row gy-3 mb-4">
                    <div className="col-lg-5">
                      <div className="me-lg-5">
                        <div className="d-flex">
                          {product.imageName ? (
                            <img
                              className="border rounded me-3"
                              style={{ width: "96px", height: "96px" }}
                              src={
                                process.env.REACT_APP_STATIC_API_URL +
                                "/items/" +
                                product.imageName
                              }
                              alt={product.name}
                            />
                          ) : (
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/images/dummy/product-image-placeholder.jpg"
                              }
                              className="border rounded me-3"
                              style={{ width: "96px", height: "96px" }}
                              alt={product.name}
                            />
                          )}
                          <div className="">
                            <a href="#" className="nav-link">
                              {product.name}
                            </a>
                            <p
                              className="text-muted"
                              style={{ marginBottom: "5px" }}
                            >
                              {product.code}
                              {product.batchNumber !== 0
                                ? "." + product.batchNumber
                                : ""}
                              {product.subCode !== 0
                                ? "." + product.subCode
                                : ""}
                            </p>
                            <p className="text-danger">
                              {product.stock == 0
                                ? "Out of stock!"
                                : product.stock &&
                                  product.stock < 10 &&
                                  product.stock > 0
                                ? "Only " + product.stock + " items available!"
                                : ""}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-6 d-flex flex-row flex-lg-column flex-xl-row text-nowrap smgm-item-container">
                      <div className="smgm-item-detail">
                        <text className="h6">
                          Rs{" "}
                          {(product.price || 0) * (product.addedQuantity || 0)}
                        </text>{" "}
                        <br />
                        <small className="text-muted">
                          {product.customizedQuantity} {product.customizedUnit}
                          {": Rs. " + product.price}
                        </small>
                      </div>
                      <div className="smgm-item-button-add">
                        <UpdateQuantityButton
                          stock={product.stock}
                          value={product.addedQuantity || 0}
                          onClick={(value) =>
                            handleChangeQuantityButtonClick(
                              product.id || 0,
                              value
                            )
                          }
                        />
                      </div>
                      <div className="float-md-end smgm-item-button-remove">
                        <a
                          href="#"
                          className="btn btn-light border text-danger icon-hover-danger"
                          onClick={() => handleRemoveClick(product.id || 0)}
                        >
                          {" "}
                          Remove
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div>
                <div className="border-top pt-4 mx-3 mb-4 smgm-cart-footer-container">
                  <div className="smgm-cart-footer-image">
                    {contextSettings.companyQRCodePath && (
                      <img
                        src={
                          process.env.REACT_APP_STATIC_API_URL +
                          "/company/" +
                          contextSettings.companyQRCodePath
                        }
                        className="border rounded me-3"
                        style={{ width: "96px", height: "96px" }}
                        alt="QR Code"
                      />
                    )}
                  </div>
                  <div className="smgm-cart-footer-text">
                    <div>
                      <i className="fas fa-truck text-muted fa-lg"></i> Order
                      will be delivered within 1-2 business days!
                    </div>
                    <div>
                      <i className="fas fa-info-circle text-muted fa-lg"></i>{" "}
                      Purchased items can be returned within 4 days!!
                    </div>
                    <p className="text-muted"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="card mb-3 border shadow-0">
              <div className="card-body">
                <form>
                  <div className="form-group">
                    <label>
                      <strong>Delivery Address</strong>
                    </label>
                    <hr />
                    <div className="input-group" style={{ padding: "2px" }}>
                      <input
                        type="text"
                        className={`form-control border smgm-input-memberid ${
                          isMemberIdInvalid ? "is-invalid" : ""
                        }`}
                        name=""
                        maxLength={5}
                        placeholder="Member Id*"
                        onChange={(e: any) => setMemberId(e.target.value)}
                      />
                      {isMemberIdInvalid && (
                        <div className="invalid-feedback">
                          Should be in (M0001) format.
                        </div>
                      )}
                    </div>

                    <div className="input-group" style={{ padding: "2px" }}>
                      <input
                        type="text"
                        className="form-control border"
                        name=""
                        value={name}
                        placeholder="Name*"
                        onChange={(e: any) => {
                          const inputText = e.target.value;
                          if (!/\d/.test(inputText)) {
                            setName(inputText);
                          }
                        }}
                      />
                    </div>
                    <div className="input-group" style={{ padding: "2px" }}>
                      <input
                        type="number"
                        className="form-control border"
                        name=""
                        value={contactNumber}
                        placeholder="Contact Number*"
                        onChange={(e: any) => {
                          const inputText = e.target.value;
                          if (inputText.length <= 10) {
                            setContactNumber(inputText);
                          }
                        }}
                      />
                    </div>
                    <div className="input-group" style={{ padding: "2px" }}>
                      <textarea
                        className="form-control border"
                        name=""
                        rows={1}
                        placeholder="Address*"
                        onChange={(e: any) => setAddress(e.target.value)}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="card shadow-0 border">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <p className="mb-2">Sub Total:</p>
                  <p className="mb-2">Rs {subTotal}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className="mb-2">Delivery Charge:</p>
                  <p className="mb-2">
                    Rs{" "}
                    {contextSettings?.maximumOrderAmount != 0.0 &&
                    subTotal < (contextSettings?.maximumOrderAmount || 0.0)
                      ? contextSettings?.deliveryCharge || 0.0
                      : 0.0}
                  </p>
                </div>
                <hr style={{ margin: "0.5rem 0" }} />
                <div className="d-flex justify-content-between">
                  <p className="mb-2 fw-bold">Total Amount:</p>
                  <p className="mb-2 fw-bold">
                    Rs{" "}
                    {subTotal +
                      (contextSettings?.maximumOrderAmount != 0.0 &&
                      subTotal < (contextSettings?.maximumOrderAmount || 0.0)
                        ? contextSettings?.deliveryCharge || 0.0
                        : 0.0)}
                  </p>
                </div>

                <div className="">
                  <a
                    href="#"
                    className={
                      "btn w-100 btn-primary shadow-0 mb-2 " +
                      (enableOrderButton ? "" : "disabled")
                    }
                    onClick={handleOrder}
                  >
                    {" "}
                    Place Order
                    <p style={{ fontSize: "12px", marginBottom: "0px" }}>
                      COD (Cash On Delivery)
                    </p>
                  </a>
                  <a
                    href="#"
                    className="btn btn-secondary w-100 border"
                    style={{ backgroundColor: "darkblue" }}
                    onClick={handleBackToShopping}
                  >
                    {" "}
                    Back To Shopping{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
