import { useEffect, useState } from "react";
import axios from "axios";
import { Header } from "../../_components/header";
import { NotificationModal } from "../../_components/modals/notification-modal";
import "./AboutUs.css";

export const AboutUs = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [firstParagraphText, setFirstParagraphText] = useState<string>("");
  const [secondParagraphText, setSecondParagraphText] = useState<string>("");
  const [thirdParagraphText, setThirdParagraphText] = useState<string>("");
  const [signatureText, setSignatureText] = useState<string>("");
  const [imagePath, setImagePath] = useState<string>("");
  const [noticePath, setNoticePath] = useState<string>("");

  useEffect(() => {
    setShowModal(true);
    let url = `${process.env.REACT_APP_API_URL}/page-settings/aboutus`;

    setIsLoading(true);

    try {
      axios.get(url).then((response: any) => {
        if (response.data.length > 0) {
          setFirstParagraphText(response.data[0].FirstParagraphText);
          setSecondParagraphText(response.data[0].SecondParagraphText);
          setThirdParagraphText(response.data[0].ThirdParagraphText);
          setSignatureText(response.data[0].SignatureText);
          setImagePath(response.data[0].PageImagePath);
          setNoticePath(response.data[0].NoticeImagePath);
        }
      });
    } catch (error) {
      // Handle Error
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <Header />
      <div className="">
        <div className="smgm-aboutus-title">
          <h2>About Us</h2>
        </div>
        <div className="container smgm-aboutus-content">
          <div className="row">
            <div className="col-lg-2 col-md-2">
              {imagePath && (
                <div className="smgm-aboutus-img-container">
                  <img
                    src={
                      process.env.REACT_APP_STATIC_API_URL +
                      "/pages/" +
                      imagePath
                    }
                    alt="about-us"
                  />
                </div>
              )}
              <div className="smgm-aboutus-img-description"></div>
            </div>
            <div className="col-lg-10 col-md-10">
              <p dangerouslySetInnerHTML={{ __html: firstParagraphText }}></p>
              <p dangerouslySetInnerHTML={{ __html: secondParagraphText }}></p>
              <p dangerouslySetInnerHTML={{ __html: thirdParagraphText }}></p>
              <p dangerouslySetInnerHTML={{ __html: signatureText }}></p>
            </div>
          </div>
        </div>
      </div>
      {showModal && noticePath && (
        <NotificationModal
          imagePath={
            process.env.REACT_APP_STATIC_API_URL + "/pages/" + noticePath
          }
          onClose={handleClose}
        />
      )}
    </>
  );
};
