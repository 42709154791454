import { useState, useEffect, useRef, useContext } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import {
  SettingsContext,
  ISettingsContext,
} from "../../context/SettingsContext";
import {
  CategoryIdContext,
  ICategoryIdContext,
} from "../../context/CategoryIdContext";
import {
  ProductNameContext,
  IProductNameContext,
} from "../../context/ProductNameContext";
import { CategoryDropdown } from "../category-dropdown";
import { SearchBar } from "../search-bar";
import { NavDropdown } from "../nav-dropdown";
import { Settings } from "../../models/Settings.model";
import "./Header.css";

export const Header = () => {
  const history = useHistory();
  const { contextSettings, updateContextSettings } = useContext<
    ISettingsContext
  >(SettingsContext);

  const { contextCategoryId, updateContextCategoryId } = useContext<
    ICategoryIdContext
  >(CategoryIdContext);
  const { contextProductName, updateContextProductName } = useContext<
    IProductNameContext
  >(ProductNameContext);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toggleRef = useRef<any>(null);

  useEffect(() => {
    let url = `${process.env.REACT_APP_API_URL}/settings`;

    try {
      setIsLoading(true);
      axios.get(url).then((response) => {
        const data = response?.data;

        if (data && data.length > 0) {
          let settingsInfo: Settings = {
            id: data[0].Id,
            companyName: data[0].CompanyName,
            companyTitle: data[0].CompanyTitle,
            companyContactNo: data[0].CompanyContactNo,
            companyMobileNo: data[0].CompanyMobileNo,
            companyLogoPath: data[0].CompanyLogoPath,
            companyBannerPath: data[0].CompanyBannerPath,
            companyQRCodePath: data[0].CompanyQRCodePath,
            startingOrderNo: data[0].StartingOrderNo,
            minimumOrderAmount: data[0].MinimumOrderAmount,
            maximumOrderAmount: data[0].MaximumOrderAmount,
            deliveryCharge: data[0].DeliveryCharge,
            addedBy: data[0].AddedBy,
            addedDate: data[0].AddedDate,
            updatedBy: data[0].UpdatedBy,
            updatedDate: data[0].UpdatedDate,
          };

          updateContextSettings(settingsInfo);
        }
      });
    } catch (error) {
      // Handle Error
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        toggleRef.current &&
        !toggleRef.current.contains(event.target) &&
        !event.target.pathname
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDropdownChange = (value: number) => {
    updateContextCategoryId(value);
  };

  const handleSearchbarChange = (value: string) => {
    updateContextProductName(value);
  };

  const handleDropdownMenuClick = () => {
    setIsOpen(!isOpen);
  };

  const handleMenuClick = (event: any, page: string) => {
    event.preventDefault();
    history?.push(page);
  };

  return (
    <>
      <div className="smgm-header-wrapper">
        <header className="smgm-header">
          <div className="smgm-header-content" style={{ height: "85px" }}>
            <div style={{ display: "flex" }}>
              <div className="smgm-company-logo-wrapper">
                {contextSettings.companyLogoPath ? (
                  <img
                    className="smgm-company-logo"
                    src={
                      process.env.REACT_APP_STATIC_API_URL +
                      "/company/" +
                      contextSettings.companyLogoPath
                    }
                  />
                ) : (
                  <img
                    className="smgm-company-logo"
                    src={
                      process.env.PUBLIC_URL +
                      "/images/dummy/no-image-available.png"
                    }
                    alt="Samyukta Manab"
                  />
                )}
              </div>
              <div
                className="smgm-header-text"
                onClick={() => history.push("/")}
              >
                <div className="smgm-company-name">
                  <label>{contextSettings?.companyName || ``}</label>
                </div>
                <div className="smgm-company-title">
                  <label>{contextSettings?.companyTitle || ``}</label>
                </div>
              </div>
            </div>

            <div className="smgm-category-element">
              <div className="smgm-category-dropdown">
                <CategoryDropdown
                  onChange={(value) => handleDropdownChange(value)}
                />
              </div>
              <div className="smgm-category-searchbar">
                <SearchBar onChange={(value) => handleSearchbarChange(value)} />
              </div>
              <div className="smgm-navdropdown">
                <NavDropdown />
              </div>
            </div>
            <div className="smgm-header-text">
              <div>
                <label className="smgm-company-contact-title">Call</label>
              </div>
              <div>
                <i className="fa fa-lg fa-phone smgm-company-contact-icon"></i>
                <label className="smgm-company-contact">
                  {contextSettings?.companyContactNo || ``}
                </label>
              </div>
            </div>
          </div>
        </header>
        <header className="smgm-sub-header">
          <div className="smgm-navbar" style={{ height: "40px" }}>
            <div className="title truncate">
              <label>We have all kinds of grocery items which you need.</label>
            </div>
            <div className="title truncate">
              <label>Home Delivery - Inside of Kathmandu Muncipality</label>
            </div>

            <div>
              <ul className="links">
                <li>
                  <a
                    href="#"
                    onClick={(event) => handleMenuClick(event, "/home")}
                  >
                    Home
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    onClick={(event) => handleMenuClick(event, "/about-us")}
                  >
                    About Us
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    onClick={(event) => handleMenuClick(event, "/membership")}
                  >
                    Membership
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    onClick={(event) => handleMenuClick(event, "/service")}
                  >
                    Service
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    onClick={(event) => handleMenuClick(event, "/contact")}
                  >
                    Contact
                  </a>
                </li>
                <li>
                  <a href={process.env.REACT_APP_SACCOS_URL}>Saccos</a>
                </li>
              </ul>
              <div className="smgm-navbar-title-small">
                <span>Samyukta Manab Online Grocery Super Store</span>
              </div>
            </div>
          </div>
          <div></div>
        </header>
      </div>
    </>
  );
};
