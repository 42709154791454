import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import "./NavDropdown.css";

export const NavDropdown = () => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toggleRef = useRef<any>(null);

  const handleDropdownMenuClick = () => {
    setIsOpen(!isOpen);
  };

  const handleMenuClick = (event: any, page: string) => {
    event.preventDefault();
    history?.push(page);
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        toggleRef.current &&
        !toggleRef.current.contains(event.target) &&
        !event.target.pathname
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="smgm-dropdown-btn">
        <i
          ref={toggleRef}
          className="fa-solid fa-bars"
          onClick={handleDropdownMenuClick}
        ></i>
      </div>
      <div
        className="smgm-dropdown-menu open"
        style={isOpen ? { display: "block" } : { display: "none" }}
      >
        <li>
          <a href="#" onClick={(event) => handleMenuClick(event, "/home")}>
            Home
          </a>
        </li>
        <li>
          <a href="#" onClick={(event) => handleMenuClick(event, "/about-us")}>
            About Us
          </a>
        </li>
        <li>
          <a
            href="#"
            onClick={(event) => handleMenuClick(event, "/membership")}
          >
            Membership
          </a>
        </li>
        <li>
          <a href="#" onClick={(event) => handleMenuClick(event, "/service")}>
            Service
          </a>
        </li>
        <li>
          <a href="#" onClick={(event) => handleMenuClick(event, "/contact")}>
            Contact
          </a>
        </li>
        <li>
          <a href={process.env.REACT_APP_SACCOS_URL}>Saccos</a>
        </li>
      </div>
    </>
  );
};
