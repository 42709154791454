import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { IProductContext, ProductContext } from "../../context/ProductContext";
import { Header } from "../../_components/header";
import { OrderModal } from "../../_components/modals/order-modal";
import { ProductCart } from "../../_components/product-cart";

export const Checkout = () => {
  const history = useHistory();
  const { contextProducts, updateContextProducts } = useContext<
    IProductContext
  >(ProductContext);

  const [orderNumber, setOrderNumber] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleComplete = (orderNumber: string) => {
    setOrderNumber(orderNumber);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    updateContextProducts([]);
    history.push("/");
  };

  return (
    <>
      <Header />
      <ProductCart onComplete={handleComplete} />
      {showModal && (
        <OrderModal orderNumber={orderNumber} onClose={handleModalClose} />
      )}
    </>
  );
};
